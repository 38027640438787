import React from 'react'
import logo from '../assets/images/logo.png'

const Banner = (props) => (
    <section id="banner" className="major">
        <div className="inner">
            <header className="major">
                <div className="logo"><img src={logo} alt="" width="70%"/></div>
            </header>
            <div className="content">
                <p>Our Services Transform and Accelerate<br />
                the Way that Organisations Deliver Software and Services</p>
                <ul className="actions">
                    <li><a href="#one" className="button next scrolly">Keep Going</a></li>
                </ul>
            </div>
        </div>
    </section>
)

export default Banner



