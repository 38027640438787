import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import pic01 from '../assets/images/pic01.jpg'
import pic02 from '../assets/images/pic02.jpg'
import pic03 from '../assets/images/pic03.jpg'
import banner from '../assets/images/banner.jpg'


class HomeIndex extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet
                    title="Nubizzi Innovative IT"
                    meta={[
                        { name: 'description', content: 'Nubizzi Innovative IT Website' },
                        { name: 'keywords', content: 'Nubizzi Innovative IT Website' },
                    ]}
                >
                </Helmet>
                <Banner />

                <div id="main">
                    <section id="one" className="tiles">
                        <article style={{backgroundImage: `url(${pic03})`}}>
                            <header className="major">
                                <h3>Our solutions</h3>
                                <p>What we offer</p>
                            </header>
                            <Link to="/solutions" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic01})`}}>
                            <header className="major">
                                <h3>Our clients</h3>
                                <p>Who we work with</p>
                            </header>
                            <Link to="/clients" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic02})`}}>
                            <header className="major">
                                <h3>Our references</h3>
                                <p>What we do</p>
                            </header>
                            <Link to="/references" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${banner})`}}>
                            <header className="major">
                                <h3>About us</h3>
                                <p>Who are Nubizzi Innovative IT?</p>
                            </header>
                                <Link to="/about" className="link primary"></Link>
                        </article>
                    </section>
                </div>

            </Layout>
        )
    }
}

export default HomeIndex
